.menu-back {
    background: #8e9eab; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #eef2f3,
        #8e9eab
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #eef2f3, #8e9eab);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.menu-back-extra {
    border: none;
    box-shadow: inset 0px -6px 6px -6px black;
    padding: 9px 0px;
}

.haas-popup {
    max-height: 500px;
    max-width: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
}

.haas-popup::-webkit-scrollbar {
    display: none;
}

.haas-popup-child {
    max-height: 400px;
    max-width: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.haas-popup-child::-webkit-scrollbar {
    display: none;
}

.ant-menu-title-content {
    font-size: large;
}

// .haas-popup-scroll {
//     max-height: 300px;
//     overflow-y: scroll;
//     overflow-x: hidden;
// }

@divider-color: #ffffff;@primary-color: #9e0f19;@link-color: #177ddc;@timeline-color: #000;