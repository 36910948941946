.footer-container {
    background: #232526; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to bottom,
        #414345,
        #232526
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to bottom,
        #414345,
        #232526
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 1%;
}

@divider-color: #ffffff;@primary-color: #9e0f19;@link-color: #177ddc;@timeline-color: #000;