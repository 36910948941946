// Dark theme
// @import '~antd/dist/antd.dark.less';

// Light theme
@import '~antd/dist/antd.less';

html {
    font-size: '16px';
}
* {
    font-family: 'Poppins';
}

// Scroll style
::-webkit-scrollbar {
    width: 10px;
    background: white;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Transparent for mobile */
.element::-webkit-scrollbar {
    width: 0 !important;
}

/* Divider style -- for light theme */
.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
    border-top: 1px solid white;
}

@divider-color: #ffffff;@primary-color: #9e0f19;@link-color: #177ddc;@timeline-color: #000;